import React, { useEffect } from "react";
import SecondaryPageContainer from "components/SecondaryPageContainer/SecondaryPageContainer";
import "./datasets.scss";
import * as common from "common";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";

const s3path = common.getS3Url();

const DatasetsPage = (props) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/DatasetsPage/DatasetsPage.js",
    title: "Datasets",
  });

  const listStyle = {
    color: "#596067",
    listStyleType: "none",
  };

  const pageContents = (
    <div
      className="row DATASETS"
      id="Datasets_page-top"
      data-testid="Datasets_Page"
    >
      <div className="text-left">
        <h1>Datasets</h1>
        <p className="disclaimer">
          Please note for MER Datasets: FY23Q4 results are not yet available for
          Nigeria but FY24Q1-Q2 data has been added. A data processing error was
          found during the FY24Q2 release resulting in a small amount of data
          errors for few indicators (missing ~1,000 HTS_TST data for Malawi,
          ~500 TX_NEW for Mozambique), this will be fixed in the FY24Q3 release.
        </p>
        <section id="PDD">
          <h2 role="heading">PEPFAR Downloadable Datasets</h2>
          <p>
            The data used to populate visuals in the Dashboard section of
            Spotlight can be found here in the form of downloadable datasets.
            Data can be downloaded as compressed (zip) files, which contain text
            files in csv (comma separated values) format. Codebooks and guidance
            documents can be found within each programmatic section. For more
            information on how to work with the datasets found on Spotlight,
            please reference this{" "}
            <a
              href={`${s3path}pepfar_downloadables/Spotlight_Dataset_FAQs.docx`}
              title="Link TO FAQ document"
              aria-label="Link TO FAQ document"
            >
              FAQ document
            </a>
            .
          </p>
        </section>
        <section id="FMD">
          <h2 role="heading">Financial Management Datasets</h2>
          <h3 className="page-subheader">
            PEPFAR OU Budget to Implementing Partners
          </h3>
          <p>
            The PEPFAR OU Budgets by Financial Classifications dataset provides,
            at an OU level, what was budgeted for implementing partners for Care
            & treatment, HIV testing services, Prevention, Socio-economic, and
            Above site programs for FY2004-FY2024. Budgets for the period were
            allocated across PEPFAR budget codes and then mapped to the
            financial classifications. Information on how the &quot;Allocated
            Beneficiary&quot; column is calculated can be found{" "}
            <a
              href={`${s3path}additionalData/partner-budget-data/Allocated_Beneficiary_Guide.pdf`}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </a>
          </p>

          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}fin_mngt/budget_by_program/PEPFAR_OU_Budgets_by_Financial_Classifications.txt`}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                PEPFAR_OU_Budgets_by_Financial_Classifications.txt
              </a>
            </li>
            <li>
              <a
                href={`${s3path}fin_mngt/budget_by_program/BudgetbyProgramCodebook.csv`}
                target="_blank"
                rel="noreferrer"
              >
                Budget Financial Classifications Codebook.csv
              </a>
            </li>
            <li>
              <a
                href={`${s3path}fin_mngt/budget_by_program/BudgetCode-FinancialClassificationsCrosswalk.csv`}
                target="_blank"
                rel="noreferrer"
              >
                Budget Code Crosswalk to Financial Classifications.csv
              </a>
            </li>
            <li>
              <p>
                All Files:{" "}
                <a
                  href={`${s3path}fin_mngt/budget_by_program/PEPFAROUBudgetsbyFinancialClassifications.zip`}
                  target="_blank"
                  rel="noreferrer"
                >
                  PEPFAR OU Budgets by Financial Classifications.zip
                </a>
              </p>
            </li>
          </ul>
          <h3>Planned Funding by Budget Code</h3>
          <p>
            The PEPFAR OU Budgets by Budget Code dataset provides, at an OU
            level, what was budgeted for implementing partners during the
            COP/ROP processes for FY2004-FY2024. Technical guidance as to what
            can be included under each budget code can be updated annually;
            budget code guidance is included in the current COP/ROP guidance
            (current COP guidance is found in the Guidance section of{" "}
            <a
              href={"https://www.state.gov/reports-pepfar/"}
              target="_blank"
              rel="noreferrer"
            >
              https://www.state.gov/reports-pepfar/
            </a>{" "}
            ) As of the conclusion of the COP21 planned funding cycle in FY21
            Q3, budget code data was no longer collected. Individual budget code
            entries will indicate &#34;NOT SPECIFIED&#34; from FY21 Q3 onwards.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}fin_mngt/budget_by_budget_code/PEPFAR_OU_Budgets_by_Budget_Code.txt`}
                download
                target="_blank"
                rel="noreferrer noopener"
              >
                PEPFAR_OU_Budgets_by_Budget_Code.txt
              </a>
            </li>
            <li>
              <a
                href={`${s3path}fin_mngt/budget_by_budget_code/BudgetCodeCodebook.csv`}
                target="_blank"
                rel="noreferrer"
              >
                Budget Code Codebook.csv
              </a>
            </li>
            <li>
              <p>
                All Files:{" "}
                <a
                  href={`${s3path}fin_mngt/budget_by_budget_code/PEPFAROUBudgetsbyBudgetCode.zip`}
                  target="_blank"
                  rel="noreferrer"
                >
                  PEPFAR OU Budgets by Budget Code.zip
                </a>
              </p>
            </li>
          </ul>
          <h3>PEPFAR OU Expenditure by Implementing Partners</h3>
          <p>
            The PEPFAR Program Expenditures datasets provide, at an OU level,
            what was expended in FY2015-FY2023 by implementing partners for Care
            & treatment, HIV testing services, Prevention, Socio-economic, Above
            site programs, and Program management in US$. FY2018-FY2023
            expenditures were reported against the financial classifications for
            program, beneficiary, and object classes. FY2015-FY2017 expenditures
            were reported through expenditure analysis (EA) classifications and
            mapped to the new financial classifications.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}fin_mngt/pepfar_expenditures_by_program/PEPFAR_Program_Expenditures.txt`}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                PEPFAR_Program_Expenditures.txt
              </a>
            </li>
            <li>
              <a
                href={`${s3path}fin_mngt/pepfar_expenditures_by_program/PEPFARExpenditureAnalysis.csv`}
                target="_blank"
                rel="noreferrer"
              >
                PEPFAR Expenditure Analysis.csv
              </a>
            </li>
            <li>
              <a
                href={`${s3path}fin_mngt/pepfar_expenditures_by_program/ExpendituresCodebook.csv`}
                target="_blank"
                rel="noreferrer"
              >
                Expenditures (Financial Classifications) Codebook.csv
              </a>
            </li>
            <li>
              <a
                href={`${s3path}fin_mngt/pepfar_expenditures_by_program/Expenditures_EAClassifications_Codebook.csv`}
                target="_blank"
                rel="noreferrer"
              >
                Expenditures (EA Classifications) Codebook.csv
              </a>
            </li>
            <li>
              <a
                href={`${s3path}fin_mngt/pepfar_expenditures_by_program/EAtoFinancialClassificationsCrosswalk.xlsx`}
                target="_blank"
                rel="noreferrer"
              >
                EA to Financial Classifications Crosswalk.xlsx
              </a>
            </li>
            <li>
              <p>
                All Files:{" "}
                <a
                  href={`${s3path}fin_mngt/pepfar_expenditures_by_program/PEPFARProgramExpenditures.zip`}
                  target="_blank"
                  rel="noreferrer"
                >
                  PEPFAR Program Expenditures.zip
                </a>
              </p>
            </li>
          </ul>
        </section>
        <section id="MDD">
          <h2 role="heading">
            Monitoring, Evaluation, and Reporting (MER) Datasets
          </h2>
          <p>
            The data found on the PEPFAR Dashboards and in the datasets of this
            section, provide a view of a country’s or region’s targets and
            results against a selection of indicators that demonstrate PEPFAR’s
            progress and contributions to combat global HIV/AIDS. The Targets
            and Results presented in these datasets represent the collective
            targets and achievements for all U.S. Government agencies that
            implement PEPFAR. For more information on the indicators by which
            targets and results are reported, please consult the latest MER
            Indicator Reference Guide found in the Guidance section of{" "}
            <a href=" https://www.state.gov/reports-pepfar/" target="blank">
              https://www.state.gov/reports-pepfar/
            </a>
            .{" "}
          </p>
          <h3>Geographical Analysis – Operating Unit</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit for Fiscal
            Years 2016 – 2024 and commonly used indicators across PEPFAR’s
            Program Areas.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Geographical_Analysis_Operating_Unit.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Geographical_Analysis_Operating_Unit.zip
              </a>
            </li>
          </ul>
          <h3>Geographical Analysis – Sub-National Unit</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit and
            Sub-National Units 1-3 for Fiscal Years 2016 – 2024 and commonly
            used indicators across PEPFAR&#39;s Program Areas.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Geographical_Analysis_Sub_National_Unit.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Geographical_Analysis_Sub_National_Unit.zip
              </a>
            </li>
          </ul>
          <h3>Enhanced Geographical Analysis - Sub-National Unit</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit and
            Sub-National Units 1-3 for Fiscal Years 2016-2024 for a more
            comprehensive array of indicators across PEPFAR&#39;s Program Areas.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Enhanced_Geographical_Analysis.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Enhanced_Geographical_Analysis.zip
              </a>
            </li>
          </ul>
          <h3>Operating Unit by Fine Age & Sex</h3>
          <p>
            Quarterly Results and yearly targets by Operating Unit, Fine Age and
            Sex for Fiscal Years 2019 – 2024. Note: Reporting for age bands
            above 50 is optional and not available for all OUs; however, the
            aggregate 50+ age band is available for all OUs.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Operating_Unit_by_Fine_Age_and_Sex.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Operating Unit by Fine Age & Sex
              </a>
            </li>
          </ul>
          <h3>Country level Results by Modality, Coarse Age and Sex</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit, Country,
            Modality, Coarse Age and Sex for Fiscal Years 2018 – 2024.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Country_Level_Results_by_Modality_Coarse_Age_and_Sex.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Country Level Results by Modality Coarse Age and Sex.zip
              </a>
            </li>
          </ul>
          <h3>Clinical Cascade – Quarterly Results</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit and
            Sub-National Units 1–3 for Fiscal Years 2016 – 2024 and the
            following subset of Testing and Treatment indicators: HTS_TST
            (People receiving testing and counseling services), HTS_SELF (HIV
            Self Test Kits Distributed), HTS_TST_POS (People newly testing
            positive for HIV), TX_CURR (People currently receiving ART), TX_NEW
            (People newly enrolled in ART), TX_PVLS (Number of ART Patients with
            a suppressed VL results (&lt;1,000 copies/mL) within the Past 12
            months) and TX_NET_NEW (Net Increase or Decrease in ART Patients).
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Clinical_Cascade_Quarterly_Results.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Clinical_Cascade_Quarterly_Results.zip
              </a>
            </li>
          </ul>
          <h3>Clinical Cascade – Results by Coarse Age and Sex</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit, Sub-National
            Units 1–3, Coarse Age and Sex for Fiscal Years 2016 – 2024 and the
            following subset of Testing and Treatment indicators: HTS_TST
            (People receiving testing and counseling services), HTS_SELF (HIV
            Self Test Kits Distributed), HTS_TST_POS (People newly testing
            positive for HIV), TX_CURR (People currently receiving ART), TX_NEW
            (People newly enrolled in ART), TX_PVLS (Number of ART Patients with
            a suppressed VL results (&lt;1,000 copies/mL) within the Past 12
            months), and TX_NET_NEW (Net Increase or Decrease in ART Patients).
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Clinical_Cascade_Results_by_Coarse_Age_and_Sex.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Clinical_Cascade_Results_by_Coarse_Age_and_Sex.zip
              </a>
            </li>
          </ul>
          <h3>Clinical Cascade –Results by Fine Age and Sex</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit, Sub-National
            Unit 1, Fine Age, Coarse Age and Sex for Fiscal Years 2019 - 2024
            and the following subset of Testing and Treatment indicators:
            HTS_TST (People receiving testing and counseling services),
            HTS_TST_POS (People newly testing positive for HIV), TX_CURR (People
            currently receiving ART), TX_NEW (People newly enrolled in ART),
            TX_PVLS (12 Months Viral Load &lt; 1000), and TX_NET_NEW (Net
            Increase or Decrease in ART Patients). Note: Reporting for age bands
            above 50 is optional and not available for all OUs; however, the
            aggregate 50+ age band is available for all OUs. Note: Reporting for
            age bands above 50 is optional and not available for all OUs;
            however, the aggregate 50+ age band is available for all OUs.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Clinical_Cascade_Results_by_Fine_Age_and_Sex.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Clinical_Cascade_Results_by_Fine_Age_and_Sex.zip
              </a>
            </li>
          </ul>
          <h3>Voluntary Male Medical Circumcisions</h3>
          <p>Yearly Results by Operating Unit for Fiscal Years 2007 – 2023.</p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Voluntary_Male_Medical_Circumcisions_Results_by_Operating_Unit.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Voluntary_Male_Medical_Circumcisions_Results_by_Operating_Unit.zip
              </a>
            </li>
          </ul>
          <h3>Partner Performance</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit, PSNU,
            Implementing Partner (masked) and Funding Agency for Fiscal Years
            2017 – 2024 and commonly used indicators across PEPFAR’s Program
            Areas.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Partner_Performance.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Partner_Performance.zip
              </a>
            </li>
          </ul>
          <h3>Site Performance</h3>
          <p>
            Quarterly Results and yearly Targets by Operating Unit, PSNU and
            Facility/Site (masked) for Fiscal Years 2015 – 2024 and commonly
            used indicators across PEPFAR&#39;s Program Areas.
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Site_Performance_2021_plus.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Site_Performance_2021_plus.zip
              </a>
            </li>

            <li>
              <a
                href={`${s3path}mer_downloadables/Site_Performance_2016-2020.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Site Performance 2016-2020
              </a>
            </li>
          </ul>
          <h3>Cervical Cancer Analysis</h3>
          <p>
            Bi-annual Results by Operating Unit and Fine Age for Fiscal Years
            2018-2024 and the following Cervical Cancer indicators: CXCA_SCRN
            (Number of HIV-positive women on ART screened for cervical cancer),
            CXCA_SCRN_POS (Number of HIV-positive women on ART who screened
            positive for cervical cancer), and CXCA_TX (Number of cervical
            cancer screen-positive women who are HIV-positive and on ART and
            received precancerous cervical cancer treatment).
          </p>
          <ul style={listStyle}>
            <li>
              <a
                href={`${s3path}mer_downloadables/Cervical_Cancer_Analysis.zip`}
                target="_blank"
                rel="noreferrer"
              >
                Cervical_Cancer_Analysis.zip
              </a>
            </li>
          </ul>
        </section>
        <section id="SDD">
          <h2 role="heading">
            Site Improvement through Monitoring System (SIMS) Datasets
          </h2>
          <p>
            Global SIMS data are provided for FY19 Q2 onwards (i.e. January 2019
            onwards). This aligns with the beginning of SIMS 4.0 implementation.
            Data are provided down to the SNU1 level for each CEE in each OU.
            Please refer to the{" "}
            <a
              href={`${s3path}sims_downloadables/SIMS.Data.Dictionary.to.accompany.downloadable.datasets.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Data Dictionary
            </a>{" "}
            and the materials referenced in the Site Improvement Through
            Monitoring System dashboard Cover Page for details on how SIMS
            implementation occurs and how SIMS data are structured.
          </p>
          <p>
            This is a zip file contains data from all OUs as a separate files:{" "}
            <a
              href={`${s3path}sims_downloadables/SIMS_Structured_Datasets_OU_SNU1_SIMS4.zip`}
            >
              SIMS_Structured_Datasets_OU_SNU1_SIMS4.zip
            </a>
          </p>
          <p>
            A single excel file containing data from all OUs:{" "}
            <a
              aria-label="Link opens SIMS_Structured_Datasets_SNU1_SIMS4.xlsx"
              href={`${s3path}sims_downloadables/SIMS_Structured_Datasets_SNU1_SIMS4.xlsx`}
            >
              SIMS_Structured_Datasets_SNU1_SIMS4.xlsx
            </a>
          </p>
        </section>
        <section id="PAS">
          <h2 role="heading">PASIT Downloadable Datasets</h2>
          <p>
            The Planning Activities for Systems Investment Tool (PASIT) is as a
            PEPFAR Country or Regional Operational Plan (COP or ROP) planning
            tool designed to help connect gaps in public health systems with
            above-site activities and outcomes that can contribute to ending
            HIV/AIDS as a public health threat by 2030.
          </p>
          <p>
            PASIT data includes: activity descriptions, types of health system
            investments, activity budgets, who is implementing those activities,
            expected outcomes of activities etc. PASIT applies to the COP and
            ROP 2023 planning cycles.
          </p>
          <p>
            Please refer to the User Guide in the zip file for details and
            definitions of each data field.
          </p>
          <p>
            <a href={`${s3path}pasit/PEFPAR_PASIT_by_OU_and_partner.zip`}>
              PASIT COP23 dataset & User Guide
            </a>
          </p>
        </section>
      </div>
    </div>
  );

  useEffect(() => {
    renderTOC();
  }, []);

  function renderTOC() {
    const tocList = document.getElementById("toc");
    let sectionElement, headerElement, title, link;

    const sectionHeaders = document.querySelectorAll("section");

    // Build HTML Dynamically for Table of Contents
    for (let i = 0; i < sectionHeaders.length; i++) {
      sectionElement = sectionHeaders[i];
      headerElement = sectionElement.getElementsByTagName("h2")[0];
      if (headerElement) {
        title = headerElement.innerText;
        link = "#" + sectionElement.id;

        const tmpNode = document.createElement("li");
        const tmpLink = document.createElement("a");
        tmpLink.innerText = title;
        tmpLink.href = link;
        tmpNode.appendChild(tmpLink);

        tocList.appendChild(tmpNode);
      }
    }
  }

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={true}
      pageHeaderId="Datasets_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

DatasetsPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default DatasetsPage;

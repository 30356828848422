import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "mdbreact";
import videoImage from "assets/images/NewPepfarVideo.png";
import videoImageHover from "assets/images/NewPepfarVideoHover.png";
import logoImage from "assets/images/PEPFAR_20th_Logo.png";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { Grid, Box } from "@mui/material";

// import logger from "services/LoggingService";

import "./InTheSpotlight.scss";

const initialState = {
  isModalDisplayed: false,
  currentVideoImage: videoImage,
};

const InTheSpotlight = (props) => {
  const appContext = useSelector(selectCompliance).context;
  const [isHidden, setIsHidden] = useState(false);
  const [stateInfo, setStateInfo] = useState(initialState);

  useEffect(() => {
    const hide = appContext !== "home";
    setIsHidden(hide);
  });

  const toggleModalDisplayed = () => {
    setStateInfo({
      ...stateInfo,
      isModalDisplayed: !stateInfo.isModalDisplayed,
    });
  };

  const videoHoveredMouseOver = () => {
    setStateInfo({
      ...stateInfo,
      currentVideoImage: videoImageHover,
      altText: "Video: Dr. John Nkengasong commemorates 35th World Aids Day.",
    });
  };

  const videoHoveredMouseOut = () => {
    setStateInfo({
      ...stateInfo,
      currentVideoImage: videoImage,
    });
  };

  return (
    <>
      <div className="in-spotlight-row">
        <Grid
          container
          rowSpacing="4vh"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Box
              spacing={0}
              alignItems="center"
              justifyContent="center"
              sx={{ maxHeight: "25vh" }}
            >
              <Modal
                isOpen={stateInfo.isModalDisplayed}
                toggle={toggleModalDisplayed}
                className="spotlight-video"
                overflowScroll={false}
                frame
                backdrop
                role="dialog"
                aria-modal="true"
                aria-hidden="true"
              >
                <ModalHeader
                  toggle={toggleModalDisplayed}
                  id="videotitle"
                  title="Live from Durban"
                ></ModalHeader>
                <ModalBody>
                  <div className="spotlight-video">
                    <video controls autoPlay width={600} height={400}>
                      <source src="/videos/WAD23.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </ModalBody>
              </Modal>
              <div
                role="button"
                data-testid="videoBtnDiv"
                // className="img-fluid"
                aria-hidden={isHidden}
                tabIndex={isHidden ? -1 : 0}
                onMouseOver={videoHoveredMouseOver}
                onMouseOut={videoHoveredMouseOut}
                onClick={toggleModalDisplayed}
              >
                <img
                  className="spot-img"
                  id="videobutton"
                  data-testid="vidBtn_1"
                  alt="Open video of Dr. John Nkengasong."
                  src={stateInfo.currentVideoImage}
                ></img>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              spacing={0}
              alignItems="center"
              justifyContent="center"
              sx={{ maxHeight: "25vh" }}
            >
              <NavLink
                data-testid="dossierLink"
                to="/Pepfar20Years"
                exact
                aria-hidden={isHidden}
                tabIndex={isHidden ? -1 : 0}
              >
                <div id="twentiethAnniversaryWidget">
                  <img
                    className="spot-img"
                    src={logoImage}
                    id="videoHover"
                    data-testid="vidBtn_2"
                    alt="Link to Pepfar 20 years of impact dossier"
                  ></img>
                </div>
              </NavLink>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default InTheSpotlight;

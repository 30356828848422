// Place common Javascript utilities/functions here to be used across whole website
import logger from "services/LoggingService";

const mobileBreakpoint = [767];
const smallBreakpoint = [1024, 1801];
const xlBreakpoint = [3800];
const largeBreakpoint = [2880, 3800];
const hugeBreakpoint = [2880, 1800];

// const tabletBreakpoint = 1024
export const iPadDimensions = { width: 768, height: 1024 };

export function getFileExtension(fileName) {
  const a = fileName.split(".");
  if (a.length === 1 || (a[0] === "" && a.length === 2)) {
    return "";
  }

  return a.pop();
}

export function isBrowserIE11() {
  // Detect if browser is IE11
  const userAgent = window.navigator.userAgent;
  const msie = userAgent.indexOf("Trident/");
  if (msie > 0) {
    return true;
  }
  return false;
}

export function useMicrostrategyDev() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "data-dev.pepfar.gov" ||
    window.location.hostname === "data-dev-2.pepfar.gov"
  ) {
    return true;
  }
  return false;
}

export function getMicroStrategyUrl() {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "data-dev.pepfar.gov" ||
    window.location.hostname === "data-dev-2.pepfar.gov"
  ) {
    return "devURL";
  } else if (window.location.hostname === "data-staging.pepfar.gov") {
    return "stagingURL";
  }
  return "prodURL";
}

export function getS3Url() {
  const host = window.location.hostname;
  switch (host) {
    case "localhost":
    case "data-dev.pepfar.gov":
    case "data-dev-2.pepfar.gov":
      return "https://s3.amazonaws.com/media.data-dev.pepfar.gov/";

    case "data-staging.pepfar.gov":
      return "https://s3.amazonaws.com/media.data-staging.pepfar.gov/";

    case "data.pepfar.gov":
      return "https://s3.amazonaws.com/media.data.pepfar.gov/";
  }
}

export function objCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const layout = {
  QUAD: "QUAD",
  HALF: "HALF",
  FULL: "FULL",
};

export function getChartFontSize() {
  let fontSize;
  if (isMobile() || isTablet()) {
    fontSize = 9;
  } else if (isXL()) {
    fontSize = 24;
  } else if (isHuge()) {
    fontSize = 28;
  } else if (isLarge()) {
    fontSize = 14;
  } else if (isSmall()) {
    fontSize = 13;
  } else {
    fontSize = 13;
  }
  return fontSize;
}

export function getChartTitleSize() {
  return getChartFontSize() + 2;
}

export function getBarLabelPosition() {
  return isMobile() || isTablet() ? "top" : "inside";
}

export function isMobile() {
  return getWindowDimensions().width <= mobileBreakpoint[0];
}

export function isTablet() {
  return isTabletLayout(getWindowDimensions());
}

export function isSmall() {
  const dim = getWindowDimensions();
  return dim.width >= smallBreakpoint[0] && dim.width < smallBreakpoint[1];
}

export function isLarge() {
  const dim = getWindowDimensions();
  return dim.width >= largeBreakpoint[0] && dim.width < largeBreakpoint[1];
}

export function isXL() {
  return getWindowDimensions().width >= xlBreakpoint[0];
}

export function isHuge() {
  const dim = getWindowDimensions();
  return dim.width >= hugeBreakpoint[0] && dim.height >= hugeBreakpoint[1];
}

export function isMobileLayout(windowDimensions) {
  return windowDimensions.width <= mobileBreakpoint[0];
}

export function isTabletLayout(windowDimensions) {
  return (
    windowDimensions.width === iPadDimensions.width &&
    windowDimensions.height === iPadDimensions.height
  );
}

export function isXlLayout(windowDimensions) {
  return windowDimensions.width >= xlBreakpoint;
}

export function getScreenCenter(windowDimensions) {
  return {
    width: windowDimensions.width / 2,
    height: windowDimensions.height / 2,
  };
}

export function getMapSize(input) {
  const windowDimensions = getWindowDimensions();
  const mobilePhone = isMobile();
  // const tablet = isTablet(windowDimensions)
  const size = { width: 0, height: 0 };
  switch (input) {
    case layout.QUAD:
      size.width =
        mobilePhone === true
          ? windowDimensions.width * 0.9
          : windowDimensions.width * 0.4;
      size.height =
        mobilePhone === true
          ? windowDimensions.height * 0.3
          : windowDimensions.height * 0.3;
      break;

    case layout.HALF:
      size.width =
        mobilePhone === true
          ? windowDimensions.width * 0.9
          : windowDimensions.width * 0.35;
      size.height =
        mobilePhone === true
          ? windowDimensions.height * 0.3
          : windowDimensions.height * 0.5;
      break;

    case layout.FULL:
      size.width = windowDimensions.width;
      size.height = windowDimensions.height;
      break;

    default:
      logger.error("Invalid map size requested");
  }
  return size;
}

export function getChartSize(input) {
  const windowDimensions = getWindowDimensions();
  const mobilePhone = isMobile();
  // const tablet = isTablet(windowDimensions)
  const size = { width: 0, height: 0 };

  switch (input) {
    case layout.QUAD:
      size.width =
        mobilePhone === true
          ? windowDimensions.width * 0.9
          : windowDimensions.width * 0.4;
      size.height =
        mobilePhone === true
          ? windowDimensions.height * 0.3
          : windowDimensions.height * 0.3;
      break;

    case layout.HALF:
      size.width =
        mobilePhone === true
          ? windowDimensions.width * 0.9
          : windowDimensions.width * 0.35;
      size.height =
        mobilePhone === true
          ? windowDimensions.height * 0.3
          : windowDimensions.height * 0.5;
      break;

    case layout.FULL:
      size.width = windowDimensions.width;
      size.height = windowDimensions.height;
      break;

    default:
      logger.error("Invalid map size requested");
  }
  return size;
}

export function runningJest() {
  return process.env.JEST_WORKER_ID !== undefined;
}

export function checkCache() {
  return !runningJest(); // && process.env.NODE_ENV === 'production'
}

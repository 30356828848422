import React from "react";
import CollapsibleSection from "components/SecondaryPageContainer/CollapsibleSection/CollapsibleSection";
import ReactHtmlParser from "react-html-parser";
import { v4 as uuid } from "uuid";
import "components/SecondaryPageContainer/SectionBuilder/SectionBuilder.scss";

const sectionBuilder = (props) => (
  <section>
    <h1>{props.topLevelHeader}</h1>
    {props.sectionTitles.map((sectionTitle, sectionTitleIndex) => (
      <div className="section-outer__div" key={uuid()}>
        <h2 id={props.sectionIds[sectionTitleIndex]}>{sectionTitle}</h2>
        {props.sectionDescriptions.has(sectionTitle) ? (
          <div>
            {ReactHtmlParser(props.sectionDescriptions.get(sectionTitle))}
          </div>
        ) : null}
        {props.jsonSectionsConfig.sections.map(
          (currentSection, currentSectionIndex) =>
            currentSection.sectionTitle === sectionTitle ? (
              <CollapsibleSection
                key={uuid()}
                collapsibleSectionHeader={currentSection.subSectionHeader}
                collapsibleSectionParagraphs={
                  currentSection.subSectionParagraphs
                }
              />
            ) : null
        )}
      </div>
    ))}
  </section>
);

export default sectionBuilder;

import React, { Fragment, useState, useRef } from "react";
import {
  MDBTable,
  MDBTableBody,
  MDBNavItem,
  MDBNav,
  MDBNavLink,
  MDBBtn,
} from "mdbreact";
import PropTypes from "prop-types";
import SecondaryPageContainer from "components/SecondaryPageContainer/SecondaryPageContainer";
import DAAMapChart from "./DAAMapChart";
import { drawDaaMap } from "./DaaMap";
import TxCurrFormula from "assets/images/MapPage/TX_CURR-Formula.png";
import TxCurrFormulaWebp from "assets/images/WEBP/TX_CURR-Formula.webp";
import { tableSort } from "services/MessagingService";
import WebpElement from "components/WebpElement/WebpElement";
import inputData from "./DaaMapGeoData.json";
import { useSelector, useDispatch } from "react-redux";
import { selectDaa, updateDaaState } from "stateMgmt/daaSlice";
import { selectTheme } from "../../stateMgmt/complianceSlice";
import logger from "services/LoggingService";
import ReactGA from "react-ga4";
import "./DAAMapPage.scss";
import MapData from "./MapData";
import { v4 as uuid } from "uuid";

// ********************** MapPage1 Component **********************
const MapPage1 = (props) => {
  const mapRef = useRef();
  const currentState = useSelector(selectDaa);
  const theme = useSelector(selectTheme);
  const dispatch = useDispatch();
  const [initiialized, setInitiialized] = useState(false);
  const linkStyle = {
    color: theme.linkColor,
    fontWeight: 800,
  };

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/AdditionalDataPage/AdditionalDataPage.js",
    title: "Additional Data",
  });

  const getFyIndicators = (year) => {
    return year === "FY2022"
      ? inputData.trackedIndicators[1]
      : inputData.trackedIndicators[0];
  };

  const loadButton = (btnNumber, isActive, label) => {
    return (
      <MDBBtn
        className="rounded daaIndBtn"
        key={uuid()}
        color={isActive ? "light-blue" : "blue-grey"}
        // disabled={isLoading}
        size="sm"
        onClick={(e) => {
          toggleIndicatorButtons(btnNumber, e);
        }}
      >
        {label}
      </MDBBtn>
    );
  };

  const loadButtonBar = (num, trackedIndicators) => {
    logger.debug("DAAMapChart loadButtonBar called");
    const buttons = [];
    trackedIndicators.forEach((indicator, index) => {
      buttons.push(
        loadButton(index, index === num, indicator.replace("_", "  "))
      );
    });
    return buttons;
  };

  const getChartData = (trackedIndicators) => {
    logger.debug("DAAMapChart getChartData called");
    const buffer = [];
    trackedIndicators.forEach((indicator, idx) => {
      const template = {
        indicator: { indicator },
        data: [],
      };

      inputData.features.forEach((country) => {
        const label = country.properties.id.toLowerCase();
        const name = country.properties.name;
        const data = country.properties.rowData[currentState.displayedYear];
        let val = null;
        let total = null;

        if (country.properties.tracked) {
          val = isNaN(data[idx].value) ? -1 : Number(data[idx].value);
          total = data[idx].total;
        }

        const newVal = {
          tracked: country.properties.tracked,
          "hc-key": label,
          value: val,
          name,
          id: name,
          total,
          indicators: data,
          year: currentState.displayedYear,
        };
        template.data.push(newVal);
      });

      buffer.push(Object.assign({}, template));
    });

    return buffer;
  };

  const pageContents = (
    <div id="MapPage1_page-top" data-testid="Map_Page1">
      <div className="text-left">
        {/* START: 'MapPage 1' */}
        <h1>Data Alignment Activity </h1>
        <br />
        <section id="section 1">
          <h2>PEPFAR/MOH Data Alignment Activity</h2>
          <p>
            PEPFAR would like to acknowledge the phenomenal work and
            collaborative efforts made by country teams towards the 2022
            PEPFAR/MOH Data Alignment Activity. 2022 marks the fifth year of
            this activity, which aims to align Ministry and PEPFAR data systems,
            building the foundation for results collection centered around the
            national platform, building efficiencies and a greater focus on the
            use of the HIV data.
          </p>
          <p>
            We would like to publicly recognize the considerable work that has
            been invested in the alignment of information systems, the progress
            made to date, and to further highlight where PEPFAR needs to focus
            its attention to ensure confidence in the data necessary to
            contribute to the control of the HIV epidemic. As with all data,
            there are many variables that impact the numbers being reported,
            such as rolling out updates to indicator definitions or the
            transition to a new reporting system.  Nevertheless, we have seen
            substantial improvements in the level of concordance over the five
            years of this activity and anticipate this trajectory to continue as
            reporting systems and processes are strengthened.
          </p>
          <p>
            Our intention is to resolve challenges we are facing together; being
            transparent and sharing shortcomings with each other and civil
            society will reveal where PEPFAR can be more accountable and help
            reach our goal of having 100% alignment on the figures reported
            through PEPFAR and the Ministry.
          </p>
        </section>
        <br />
        <br />
        <section id="section 2">
          <h2>Overview</h2>
          <p>
            The PEPFAR/MOH Data Alignment Activity is an initiative to help
            understand the full HIV landscape and better define targeted
            interventions.  Facility level HIV results from the Ministries of
            Health from 23 countries and from PEPFAR are brought together and
            aligned to provide a comprehensive and unambiguous view of national
            programs.
          </p>
          <p>
            These results are being used to identify reporting challenges, with
            the goal to improve reporting processes, data quality, and program
            efficiencies. We aim to build more confidence in the data, which
            happens when the numbers from the PEPFAR and from MOH align.
            Secondly, we want to begin laying the groundwork for data collection
            centered around the national platform - building on efficiencies and
            prioritizing data quality and granularity of HIV data.
          </p>
        </section>
        <br />
        <br />
        <section id="map">
          <h2>Daa Map</h2>
          <p>
            {" "}
            This published data shows the level of disaggregation for which the
            indicators have been mapped (fine versus coarse disaggregation) and
            the overall (national level) weighted concordance for each. The
            weighted concordance is taken from comparing the PEPFAR and MOH
            collected results covering PEPFAR’s 2020-2022 fiscal years.
          </p>{" "}
          <div className="row" id="table-map">
            <div className="daa-note">
              * FY2021: Ukraine, Mozambique and South Africa participated in the
              Data Alignment Activity but data is not shown
            </div>
            <div className="daa-note">
              * FY2022: Ukraine, Mozambique, South Africa and Vietnam
              participated in the Data Alignment Activity but data is not shown
            </div>

            <br />
            <div className="col-lg-12 Map_page">
              {<DAAMapChart initialYear={currentState.displayedYear} />}
            </div>
            <div className="fy-sel-container">
              <div className="nav-label">
                Select fiscal year for table and map{" "}
              </div>
              <div>
                {" "}
                <MDBNav className="nav-pills nav-fill flex-column flex-lg-row">
                  {currentState.fiscalYears.map((year, index) => (
                    <MDBNavItem key={uuid()}>
                      <MDBNavLink
                        link
                        data-testid={`navLink${index}`}
                        key={uuid()}
                        to="#"
                        active={currentState.displayedYear === year}
                        onClick={() => toggleFiscalYear(year)}
                        role="tab"
                        className="fy-select"
                      >
                        {year}
                      </MDBNavLink>
                    </MDBNavItem>
                  ))}
                </MDBNav>
              </div>
            </div>
          </div>
          <br />
        </section>
        <section id="table">
          <h2>Indicator Table</h2>
          {renderDataTable()}
        </section>
        <br />
        <br />
        <section id="section 3">
          <h2>The Data</h2>
          <p>
            The Data Alignment Activity collects results, with either fine or
            coarse disaggregation, on the following seven key HIV indicators.
          </p>
          <p>
            <b>HTS_TST</b>: Number of individuals who received HIV testing
            services and received their test results (by age, sex and HIV test
            status)
          </p>
          <ul>
            <li>
              Fine disaggregation consisting of HIV test status, sex and
              detailed age bands (five-year increments)
            </li>
            <li>
              Coarse disaggregation consisting of HIV test status, sex and
              coarse age bands (&lt;15 and 15+)
            </li>
          </ul>

          <p>
            <b>PMTCT_ART</b>: Number of HIV-positive pregnant women who received
            antiretroviral therapy to reduce the risk of mother-to-child-
            transmission during pregnancy
          </p>
          <ul>
            <li>Total</li>
          </ul>

          <p>
            <b>PMTCT_STAT</b>: Number of pregnant women with known HIV status at
            antenatal care, including those who already knew their HIV status
            prior to ANC (by age and HIV test status)
          </p>
          <ul>
            <li>
              Fine disaggregation consisting of HIV test status (known positive,
              newly tested positives, new negatives, recent negative) and
              detailed age bands (five-year increments)
            </li>
            <li>
              Coarse disaggregation consisting of HIV test status (known
              positive, newly tested positives, new negatives, recent negative)
            </li>
          </ul>

          <p>
            <b>TX_NEW</b>: Number of adults and children newly enrolled on
            antiretroviral therapy (by age and sex)
          </p>
          <ul>
            <li>
              Fine disaggregation consisting of sex and detailed age bands
              (five-year increments)
            </li>
            <li>
              Coarse disaggregation consisting of sex and coarse age bands
              (&lt;15 and 15+)
            </li>
          </ul>

          <p>
            <b>TX_CURR</b>: Number of adults and children currently receiving
            antiretroviral therapy (by age and sex)
          </p>
          <ul>
            <li>
              Fine disaggregation consisting of sex and detailed age bands
              (five-year increments)
            </li>
            <li>
              Coarse disaggregation consisting of sex and coarse age bands
              (&lt;15 and 15+)
            </li>
          </ul>

          <p>
            <b>TX_PVLS (num)</b>: Number of ART patients with suppressed VL
            results (&lt;1,000 copies/ml) documented in the medical or
            laboratory records/LIS within the past 12 months
          </p>
          <ul>
            <li>
              Fine disaggregation consisting of sex and detailed age bands
              (five-year increments)
            </li>
            <li>
              Coarse disaggregation consisting of sex and coarse age bands
              (&lt;15 and 15+)
            </li>
          </ul>

          <p>
            <b>TX_PVLS (den)</b>: Number of ART patients with a VL results
            (&lt;1,000 copies/ml) documented in the medical or laboratory
            records/LIS within the past 12 months
          </p>
          <ul>
            <li>
              Fine disaggregation consisting of sex and detailed age bands
              (five-year increments)
            </li>
            <li>
              Coarse disaggregation consisting of sex and coarse age bands
              (&lt;15 and 15+)
            </li>
          </ul>

          <p>
            <b>TB_PREV</b>: Number of adults and children on antiretroviral
            therapy who completed tuberculosis preventative therapy (by age, sex
            and ART start)
          </p>
          <ul>
            <li>
              Coarse disaggregation consisting of sex, coarse age bands (&lt;15
              and 15+) and ART start (newly enrolled, previously enrolled,
              unknown)
            </li>
          </ul>

          <p>
            <a
              style={linkStyle}
              href="https://datim.zendesk.com/hc/en-us/articles/360034788112-2-Data-Alignment-Indicators"
              target="_blank"
              rel="noopener noreferrer"
            >
              Data Alignment Activity – Indicators
            </a>
          </p>
          <p>
            We use weighted concordance when comparing the results in order to
            provide more weight to facilities with a higher number of patients.
            The weighted concordance used in the table is calculated by
          </p>
          <ol>
            <li>
              Calculate the sum of the reported values for each facility that
              reported both PEPFAR and MoH results.
            </li>
            <li>
              Subtract the absolute value of the difference in reported values
              at each site.
            </li>
            <li>Divide by the sum of the reported values at each site.</li>
            <li>
              Calculate the weight for each site to account for patient volume.
              Higher volume sites will have a higher weight. Multiply the above
              result by this weight.
            </li>
            <li>
              Take the sum of those values across all sites to provide the total
              weighted concordance for the country
            </li>
          </ol>
        </section>

        <br />

        <section id="section4">
          <h2>Concordance Equation</h2>

          <div className="formula-img-container">
            <WebpElement
              className="formula-img"
              src={TxCurrFormula}
              webp={TxCurrFormulaWebp}
              alt="TxCurr Formula"
            />
          </div>
          <br />
          <p>
            The formula displays the derivation of concordance for TX_CURR. The
            equation can be used for any other indicators by substituting
            TX_CURR with the indicator of your choice.
          </p>
        </section>
        {/* END: 'MapPage 1' */}
      </div>
    </div>
  );

  React.useEffect(() => {
    logger.debug(
      `MapPage1 useEffect called (${initiialized}). State: `,
      currentState
    );

    tableSort.subscribe((newIndicator) => {
      sortTableData(newIndicator);
    });

    updatePage(currentState.displayedYear);

    if (!initiialized) {
      renderTOC();
      setInitiialized(true);
    }
  }, [
    currentState.btnSelected,
    currentState.displayedYear,
    currentState.showLabels,
    currentState.sortRow,
  ]);

  function updatePage(newYear) {
    logger.debug(
      `MapPage 1 updatePage called: ${newYear} : Indicator: ${currentState.btnSelected}`
    );

    const trackedIndicators = getFyIndicators(newYear);
    const buttonBar = loadButtonBar(
      currentState.btnSelected,
      trackedIndicators
    );
    const mapData = mapRef.current.getSortedRowData(0, newYear);
    const mapIndexFilter = getChartData(trackedIndicators);

    dispatch(
      updateDaaState({
        trackedIndicators,
        buttonBar,
        currentTableData: [...mapData],
        mapIndexFilter,
      })
    );

    drawDaaMap(
      mapIndexFilter[currentState.btnSelected].data,
      currentState.currentIndicatorIndex
    );
  }

  function toggleFiscalYear(newYear) {
    logger.debug(`MapPage 1 toggleFiscalYear called: ${newYear}`);

    const newIndex = currentState.fiscalYears.findIndex(
      (year) => year === newYear
    );
    const trackedIndicators = getFyIndicators(newYear);
    const mapData = mapRef.current.getSortedRowData("TX_CURR", newYear);

    dispatch(
      updateDaaState({
        btnSelected: 0,
        currentIndicatorIndex: 0,
        displayedYear: newYear,
        fyYearIndex: newIndex,
        currentTableData: [...mapData],
        trackedIndicators,
      })
    );
  }

  function toggleIndicatorButtons(num, event) {
    logger.debug(`MapPage 1 toggleIndicatorButtons called: ${num}`);

    dispatch(
      updateDaaState({
        btnSelected: num,
        currentIndicatorIndex: num,
        currentIndicator: currentState.trackedIndicators[num],
      })
    );
  }

  function sortTableData(newIndicator) {
    logger.debug(`sortTableData called with indicator ${newIndicator}`);
    const mapData = mapRef.current.getSortedRowData(
      newIndicator,
      currentState.displayedYear
    );
    dispatch(
      updateDaaState({
        currentTableData: [...mapData],
      })
    );

    renderDataTable();
  }

  function renderTOC() {
    logger.debug("MapPage1 renderTOC called");
    const tocList = document.getElementById("toc");
    let sectionElement, headerElement, title, link;

    const sectionHeaders = document.querySelectorAll("section");

    // Build HTML Dynamically for Table of Contents
    for (let i = 0; i < sectionHeaders.length; i++) {
      sectionElement = sectionHeaders[i];
      headerElement = sectionElement.getElementsByTagName("h2")[0];
      if (headerElement) {
        title = headerElement.innerText;
        link = "#" + sectionElement.id;

        const tmpNode = document.createElement("li");
        const tmpLink = document.createElement("a");
        tmpLink.innerText = title;
        tmpLink.href = link;
        tmpNode.appendChild(tmpLink);
        tocList.appendChild(tmpNode);
      }
    }
  }

  function renderDataTable() {
    logger.debug(
      `MapPage1 renderDataTable called`,
      currentState.currentTableData[2]
    );

    return (
      <Fragment>
        <MapData ref={mapRef} />
        <div className="mapping-table-header">
          {`${currentState.displayedYear} Indicator Mapping and Reporting`}
        </div>
        <div className="tableFixedHead">
          <MDBTable small scrollY striped fixed bordered>
            <MDBTableBody rows={currentState.currentTableData} />
          </MDBTable>
        </div>
        <div className="right-div">
          <div className="note-div">Reported for 1st Time</div>
        </div>
      </Fragment>
    );
  }

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={true}
      pageHeaderId="MapPage1_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};
// ****************************************************************

MapPage1.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default MapPage1;

import React from "react";
import PropTypes from "prop-types";

const ScrollToTopButton = (props) => {
  return (
    <a
      href={`#${props.scrollToTopLocation}`}
      aria-label="Scroll to top of page button"
    >
      <i className="fas fa-chevron-up scroll-to-top__button float-right"></i>
    </a>
  );
};

ScrollToTopButton.propTypes = {
  scrollToTopLocation: PropTypes.string,
};

export default ScrollToTopButton;

import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "mdbreact";
import { ResponsiveContainer } from "recharts";
import CountUp from "react-countup";
import * as common from "common";
import PrimaryDataChart from "./PrimaryDataChart";
import ovcBackupChartData from "assets/data-sets/kpi-bar-charts/OVC.json";
import txCurrBackupChartData from "assets/data-sets/kpi-bar-charts/TXCURR.json";
import vmmcBackupChartData from "assets/data-sets/kpi-bar-charts/VMMC.json";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
// import logger from "services/LoggingService";

import "./PrimaryDataFigure.scss";

export const createChartParams = (jsonData) => {
  const labels = [];
  const data = [];

  const key =
    jsonData.length > 0
      ? Object.keys(jsonData[0]).filter((key) => {
          return key.indexOf("FY") >= 0;
        })[0]
      : null;

  jsonData.forEach((val) => {
    const label = val["Operating Unit"];
    const value = val[key] ? val[key] : 0;
    labels.push(label);
    data.push(value);
  });

  const chartData = { labels, data };
  return chartData;
};

const PrimaryDataFigure = (props) => {
  const value = props.value;
  const previousYearValue = props.previousYearValue;
  const currentYearDescription = props.description;
  const previousYearDescription = props.previousYearDescription;
  const chartTitle = props.chartTitle;
  const chartSubTitle = props.chartSubTitle;

  const theme = useSelector(selectCompliance).theme;
  const appContext = useSelector(selectCompliance).context;
  const [showModal, setShowModal] = useState(false);
  const [displayDesciption, updateDisplayDesciption] = useState(
    previousYearDescription
  );

  useEffect(() => {}, []);

  const getBackupChartData = () => {
    if (previousYearDescription.includes("Antiretroviral")) {
      return createChartParams(txCurrBackupChartData);
    } else if (previousYearDescription.includes("Circumcisions")) {
      return createChartParams(vmmcBackupChartData);
    } else if (previousYearDescription.includes("Orphans")) {
      return createChartParams(ovcBackupChartData);
    } else {
      return {};
    }
  };

  return (
    <div style={{ height: "100%" }}>
      <Modal
        isOpen={showModal}
        contentClassName="pri-charts"
        toggle={() => setShowModal(!showModal)}
        backdrop={!common.isBrowserIE11()}
      >
        <ModalHeader toggle={() => setShowModal(!showModal)}></ModalHeader>
        <ModalBody>
          <h4 id="pri-chart-title">{chartTitle}</h4>
          <h5 id="pri-chart-sub-title">{chartSubTitle}</h5>
          <div
            className="barchart__div"
            style={{
              backgroundColor: theme.backgroundColor,
              color: theme.color,
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              {<PrimaryDataChart chartData={getBackupChartData()} />}
            </ResponsiveContainer>
          </div>
          <p id="pri-chart-footer">
            Note: OU level results may not add up to global totals as some data
            may be excluded to align with our
            <a
              href="https://datim.zendesk.com/hc/en-us/articles/360061233211-PEPFAR-Data-Governance-Guidance"
              target="_new"
            >
              {" "}
              Data Governance Guidance document.
            </a>
          </p>
        </ModalBody>
      </Modal>
      <div
        id={`cu${props.index}`}
        className="outer__div"
        style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
        onClick={() => setShowModal(!showModal)}
        role="note"
        tabIndex={appContext !== "home" ? -1 : 0}
        aria-hidden={appContext !== "home"}
      >
        <strong>
          <CountUp
            start={previousYearValue}
            delay={3}
            duration={1}
            separator={","}
            redraw={false}
            end={value}
            onEnd={() => updateDisplayDesciption(currentYearDescription)}
          />
        </strong>
        <h2
          style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
        >
          {displayDesciption}
        </h2>
      </div>
    </div>
  );
};

PrimaryDataFigure.propTypes = {
  index: PropTypes.number,
  value: PropTypes.number,
  previousYearValue: PropTypes.number,
  description: PropTypes.string,
  previousYearDescription: PropTypes.string,
  chartTitle: PropTypes.string,
  chartSubTitle: PropTypes.string,
  showModal: PropTypes.bool,
  chartDataPath: PropTypes.string,
};

export default PrimaryDataFigure;

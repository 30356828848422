import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SecondaryPageContainer from "components/SecondaryPageContainer/SecondaryPageContainer";
import reportfreq from "assets/images/reporting-frequency.png";
import reportfreqWebp from "assets/images/WEBP/reporting-frequency.webp";
import "./DataSourcesPage.scss";
import PropTypes from "prop-types";
import WebpElement from "components/WebpElement/WebpElement";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";

import ReactGA from "react-ga4";

const DataSourcesPage = (props) => {
  const theme = useSelector(selectCompliance).theme;

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/DataSourcesPage/DataSourcesPage.js",
    title: "DataSources",
  });

  const listStyle = {
    color: theme.liteFontColor,
  };

  const pageContents = (
    <div
      className="row"
      id="DataSources_page-top"
      data-testid="DataSources_Page"
    >
      <div className="text-left">
        <h1>Data Sources</h1>

        <p>
          The Presidents Emergency Plan for AIDS Relief (PEPFAR) is the U.S.
          Government initiative to help save the lives of those living with and
          affected by HIV/AIDS around the world. PEPFAR is implemented as a
          collaboration of multiple U.S. Government agencies and is coordinated
          by the Office of the U.S. Global AIDS Coordinator (see{" "}
          <a
            href="https://www.state.gov/pepfar/"
            target="_blank"
            rel="noopener noreferrer"
          >
            state.gov/PEPFAR
          </a>{" "}
          for additional information).
        </p>

        <section id="results">
          <h2>Results</h2>
          <p>
            The Targets and Results on the PEPFAR Dashboards provide a view of a
            country’s or region’s targets and results against a selection of
            indicators that demonstrate PEPFAR’s progress and contributions to
            combat global HIV/AIDS. The Targets and Results displayed on the
            PEPFAR dashboard represent the collective targets and achievements
            for all U.S. Government agencies that implement PEPFAR. For
            questions related to PEPFAR’s implementing agencies, visit{" "}
            <a
              href="https://www.state.gov/about-us-pepfar/"
              target="_blank"
              rel="noopener noreferrer"
            >
              state.gov/PEPFAR{" "}
            </a>
            .
          </p>

          <p>
            In an effort to align PEPFAR&apos;s publicly available data with
            World Health Organization (WHO) guidelines, the Targets and Results
            data have been re-structured to reflect progress towards the UNAIDS
            &quot;95-95-95&quot; goals: 95 percent of people living with HIV
            know their status, 95 percent of those diagnosed on antiretroviral
            treatment (ART), and 95 percent of those on ART virally suppressed
            by 2030. The WHO guidelines aim to prevent more new HIV infections,
            save more lives, and accelerate progress towards viral load
            suppression. Learn more about PEPFAR&apos;s implementation of the
            95-95-95 goals{" "}
            <a
              href="https://www.state.gov/pepfar-five-year-strategy-2022/#:~:text=Fulfilling%20America's%20Promise%20to%20End,public%20health%20threat%20by%202030"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </p>

          <p>
            The indicators available on the PEPFAR Public Dashboards have been
            grouped into bundles reflecting the 95-95-95 goals: Prevention,
            Testing (&quot;First 95&quot;), Treatment (&quot;Second 95&quot;),
            and Viral Suppression (&quot;Third 95&quot;). An ‘Indicator Bundle’
            selector is provided on appropriate Dashboard pages to allow data to
            be viewed by the bundles. The Clinical Cascade chapter in the
            Spotlight Dashboard displays the main indicators used to track
            progress towards the 95-95-95 goals. PEPFAR indicators are aligned
            across the Country/Regional level as well as the Sub-National Level.
            Several indicators additionally show disaggregates based on the data
            collected. Disaggregates may include age, sex, HIV status, key
            population, health resource cadre, or known/new status. Data and
            disaggregates are displayed for the years which the data is
            available in PEPFAR&apos;s official system of record. For additional
            information on the data and disaggregates that are available, please
            refer to the MER Guidance documents
            <a
              href="https://www.state.gov/pepfar-fy-2023-mer-indicators/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              here
            </a>
            .
          </p>

          <p>
            Prior to 2016, PEPFAR results were reported annually as part of the
            Annual Program Results (APR) report for the relevant Operating Unit
            (country/region) for the selected fiscal year. An APR report is the
            vehicle for documenting results achieved against applicable
            indicators within the timeframe of the fiscal year. In 2016 and
            moving forward, the results are reported quarterly, semi-annually,
            and annually depending on the indicator. Indicator results can be
            found in numerous visuals, tables and datasets in the Dashboard
            section of Spotlight, located <NavLink to="/library">here.</NavLink>
          </p>

          <div className="centered-div">
            <WebpElement
              className="sims-chart"
              src={reportfreq}
              webp={reportfreqWebp}
              alt="PEPFAR SIMS data chart"
            />
          </div>

          <p>
            The Targets and Results displayed on the PEPFAR Dashboard represent
            expected and realized achievements in the October to September U.S.
            Government’s fiscal year timeframe regardless of the appropriation
            year of the funds used to support the achievements. This means that
            the accomplishments targeted to be achieved in a given fiscal year
            and results that are reported for a given fiscal year will have been
            funded through expenditures that came from multiple planned budget
            fiscal years. Consequently, for both the indicator targets and
            results, targets and results from a given fiscal year cannot be
            directly compared to the planned funding approved in the COPs/ROPs
            for that fiscal year. Therefore, Results to Planned Funding data
            comparisons are not recommended.
          </p>

          <p>
            When reviewing the Results on the PEPFAR Dashboard, it is important
            to keep in mind that PEPFAR is in its fourth phase as of FY17.
            Target setting and results reporting have changed with each new
            phase, and in some instances, alterations were made during a phase.
            Some indicators were retained as originally defined (or kept very
            similar) to allow trend monitoring across the entire PEPFAR time
            frame (Table 1).
          </p>

          <p>
            Data collection during the first two phases differed in terms of
            reporting requirements. From 2004- 2009, counts were reported for
            what were classified as “direct (downstream)” and “indirect (or
            upstream)” support. These types of support were defined as:
          </p>

          <ul style={listStyle}>
            <li>
              An intervention or activity is considered to be a type of
              “downstream (direct) support” if it can be associated with counts
              of uniquely identified individuals receiving prevention, care,
              and/or treatment services at a unique program or service delivery
              point that receives USG funding. Downstream (direct) support is
              expected to be limited in PEPFAR Other Bilateral Countries, and
              therefore the number of downstream results may be zero. Please
              determine the number of individuals receiving prevention, care,
              and treatment services through service delivery sites/providers
              that are directly supported by USG interventions/activities
              (commodities, drugs, supplies, supervision, continuing on-site
              training, quality assurance, etc.) at the point of service
              delivery.
            </li>
            <li>
              Upstream (indirect) support refers to contributions made by the
              USG to overall system strengthening and capacity building that
              occur apart from, and at higher levels than the actual points of
              service delivery. The level of funding available for USG upstream
              programs will vary by country. While USG upstream funded
              activities will contribute to national or regional results, they
              may not contribute significantly (as defined in the PEPFAR DQA
              Tool) to these results. Upstream results are set by the national
              or regional results, if they exist, for the number of individuals
              receiving prevention, care, and treatment services, minus those
              counted above under downstream (direct) USG support.
            </li>
          </ul>

          <p>
            These distinctions were retired in 2010, with the introduction of
            the Next Generation Indicators (NGI). Only support consistent with a
            “direct” definition was to be reported. The NGI guidance defined
            direct as:
          </p>

          <ul style={listStyle}>
            <li>
              Expected achievements (targets) or realized achievements (results)
              of the PEPFAR program through its funded efforts and activities.
              These achievements may be shown in service delivery as well as in
              health workforce development, information systems, medical
              products and commodities, financing, and leadership and
              governance. As in the first 5- years of PEPFAR, “direct” can refer
              to an intervention or activity that can be associated with counts
              of uniquely identified individuals receiving prevention, care and
              support, and/or treatment services at a unique program or service
              delivery point that receives USG PEPFAR support (See appendix 5
              for more information on assessing USG Direct support for service
              delivery indicators). In addition, “direct” can refer to an
              intervention or activity that can be associated with specific
              achievements or deliverables in the other areas specified above
              such as health workforce development or policy development.
            </li>
          </ul>

          <p>
            Since the introduction of the NGI framework occurred after the start
            of the second phase of PEPFAR, the first year of planning and
            reporting during this period (FY 2009) continued to use the original
            indicators and definitions.
          </p>

          <h2>Table 1</h2>
          <div className="table-wrapper">
            <div className="DataSources_page">
              <table>
                <thead>
                  <tr>
                    <th>Indicator</th>
                    <th>2004-2009</th>
                    <th>2010-2013</th>
                    <th>2014-2016</th>
                    <th>2017-2024</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <strong>
                        <span className="break--thumb">Direct/</span>Indirect
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <span className="break--thumb">Direct/</span>
                        <span className="break--thumb">NGI</span>
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <span className="break--thumb">MER 1.0</span>
                      </strong>
                    </td>
                    <td>
                      <strong>
                        <span className="break--thumb">MER 2.0</span>
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>ANC Clients who know HIV Status</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>At-Risk Infants Tested for HIV</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Gender-based Violence Prevention</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>HIV Tested Positive</td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>HIV Testing and Counseling Services</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Key Pop Prevention Services</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>OVC Prevention Services</td>
                    <td>X</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Patients Currently Receiving ART</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Patients Newly Receiving ART</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>PMTCT Patients on ART</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Pre-Service Training</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Priority Pop Prevention Services</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Retained on ART at 12 Months</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>TB Patients on ART</td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>TB Patients who know HIV Status</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>Viral Load Suppression at 12 Months</td>
                    <td></td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                  <tr>
                    <td>VMMC</td>
                    <td></td>
                    <td>X</td>
                    <td>X</td>
                    <td>X</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <p>
            We welcome your feedback about the PEPFAR dashboard; please visit
            the <NavLink to="/contactus">Contact Us</NavLink> page to share your
            questions and feedback with us.
          </p>
        </section>
      </div>
    </div>
  );

  useEffect(() => {
    renderTOC();
  }, []);

  function renderTOC() {
    const tocList = document.getElementById("toc");
    let sectionElement, headerElement, title, link;

    const sectionHeaders = document.querySelectorAll("section");

    // Build HTML Dynamically for Table of Contents
    for (let i = 0; i < sectionHeaders.length; i++) {
      sectionElement = sectionHeaders[i];
      headerElement = sectionElement.getElementsByTagName("h2")[0];
      if (headerElement) {
        title = headerElement.innerText;
        link = "#" + sectionElement.id;

        const tmpNode = document.createElement("li");
        const tmpLink = document.createElement("a");
        tmpLink.innerText = title;
        tmpLink.href = link;
        tmpNode.appendChild(tmpLink);

        tocList.appendChild(tmpNode);
      }
    }
  }

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId="DataSources_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

DataSourcesPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};
export default DataSourcesPage;

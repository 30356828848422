import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "mdbreact";
import CountryModal from "./CountryModal";
import { messagingService } from "services/MessagingService";
import "./CountryStoryMain.scss";
import homeBanner from "assets/images/Banners/HomeBanner.png";
import {
  selectCompliance,
  updateComplianceState,
} from "stateMgmt/complianceSlice";
import ally from "ally.js";
// import Typography from "@mui/material/Typography";
import { Grid, Box } from "@mui/material";

import logger from "services/LoggingService";

const CountryStoryMain = () => {
  const dispatch = useDispatch();
  const appContext = useSelector(selectCompliance).context;
  const theme = useSelector(selectCompliance).theme;
  const [isModalOpen, setIsModalOpen] = useState(false);

  let modalSwipeStart = 0;
  let modalSwipeEnd = 0;
  let modalSwipeYStart = 0;
  let modalSwipeYEnd = 0;
  let touches = 0;
  let swiping = false;

  useEffect(() => {
    logger.debug("CountryStoryMain appContext: ", appContext);
    if (appContext !== "home") {
      setIsModalOpen(!isModalOpen);
    }
  }, [appContext]);

  const toggleModalOpen = (open) => {
    logger.debug(`toggleModalOpen: ${open}`);
    const newContext = open ? "countries" : "home";
    dispatch(updateComplianceState({ context: newContext }));

    if (open) {
      ally.maintain.tabFocus({
        context: "#story-modal",
      });
    }
  };

  const handleTouchStart = (event) => {
    touches = event.touches.length;
    modalSwipeStart = event.changedTouches[0].clientX;
    modalSwipeYStart = event.changedTouches[0].clientY;
  };

  const handleTouchMove = (event) => {
    if (event.changedTouches && event.changedTouches.length) {
      swiping = true;
    }
  };

  const handleTouchEnd = (event) => {
    if (touches === 1) {
      modalSwipeEnd = event.changedTouches[0].clientX;
      modalSwipeYEnd = event.changedTouches[0].clientY;
      const swipeDirection =
        modalSwipeEnd - modalSwipeStart > 0 ? "down" : "up";
      if (
        swiping &&
        Math.abs(modalSwipeYStart - modalSwipeYEnd) <
          Math.abs(modalSwipeStart - modalSwipeEnd)
      ) {
        messagingService.doSwipe(swipeDirection);
      }
    }

    resetTouches();
  };

  function resetTouches() {
    touches = 0;
    swiping = false;
    modalSwipeStart = 0;
    modalSwipeEnd = 0;
    modalSwipeYStart = 0;
    modalSwipeYEnd = 0;
  }

  // FORMAT: Story Modal
  return (
    <>
      <Modal
        id="story-modal"
        aria-label="Country stories slide show"
        isOpen={isModalOpen}
        toggle={() => toggleModalOpen(!isModalOpen)}
        height={"100%"}
        fullHeight={true}
        backdrop={true}
        frame={false}
        overflowScroll={true}
        role="dialog"
        name="story-modal"
        aria-modal="true"
        aria-hidden={false}
        tabIndex={"-1"}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <ModalHeader
          style={{
            backgroundColor: theme.backgroundColor,
            color: theme.color,
            border: "none",
          }}
          toggle={() => toggleModalOpen(!isModalOpen)}
        >
          <div className="stories-modal-header">
            <div>
              <a
                id="acronyms"
                href="/acronyms.html"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={0}
                name="acronyms"
                role="navigation"
                style={{ color: theme.color }}
              >
                Click here for the Glossary of Acronyms
              </a>
            </div>
            <div>
              <a
                id="back-to-spotlight"
                onClick={() => toggleModalOpen(!isModalOpen)}
                tabIndex={0}
                name="return"
                role="navigation"
                style={{ color: theme.color }}
              >
                Click here to Navigate back to Spotlight
              </a>
            </div>
          </div>
        </ModalHeader>
        <ModalBody
          style={{ backgroundColor: theme.secondaryColor, color: theme.color }}
        >
          <CountryModal />
        </ModalBody>
      </Modal>
      <div className="banner-row">
        <Grid
          container
          spacing={2}
          onClick={() => toggleModalOpen(!isModalOpen)}
          data-testid="story-main-test"
          role="button"
          id="story-main-id"
        >
          <Grid item xs={12}>
            <Box>
              <img
                src={homeBanner}
                id="wadbanner"
                name="wadbanner"
                className="home-banner-image"
                role="img"
                tabIndex={appContext !== "home" ? -1 : 0}
                aria-hidden={appContext !== "home"}
                alt="Click this image to open slideshow depicting our journey through the 20th anniversary."
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ fontSize: "1.0rem" }}>
              A fully 508 compliant deck will be made available at a later date.
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default CountryStoryMain;

import React from "react";

// import { registerTheme } from "echarts";
import ReactECharts from "echarts-for-react";
import PropTypes from "prop-types";
import { chartColors } from "components/CountryStories/charts";

// registerTheme("data/chart-themes/cool");

// const roundNumber = (params) =>
//   params[0].value > 999999
//     ? Math.round(params[0].value / 100000) * 100000
//     : Math.round(params[0].value / 1000) * 1000;

const roundNumber = (params) => Math.round(params[0].value / 1000) * 1000;

const formatTooltip = (params) => {
  return `${params[0].name}<br/> ${roundNumber(params).toLocaleString()}`;
};

export const basicBarTemplate = {
  aria: {
    show: false,
  },
  color: chartColors,
  grid: { show: true, top: 10, right: 20, bottom: 60, left: 70 },
  xAxis: {
    type: "category",
    axisTick: {
      alignWithLabel: true,
    },
    axisLabel: {
      padding: 10,
      color: "black",
      fontSize: 10,
      fontWeight: 600,
    },
    data: [],
  },
  yAxis: {
    type: "value",
    axisLabel: {
      padding: 10,
      color: "black",
      fontSize: 10,
      fontWeight: 600,
    },
  },
  series: [
    {
      data: [],
      label: {
        formatter: function (params) {
          return params.value.toLocaleString();
        },
      },
      formatter: function (params) {
        return params.value.toLocaleString();
      },
      type: "bar",
      emphasis: {
        itemStyle: {
          // Color in emphasis state.
          color: "green",
        },
      },
    },
  ],
  tooltip: {
    trigger: "axis",
    extraCssText: "box-shadow: 4px 6px 0px rgba(10, 10, 10, 0.3)",
    formatter: function (params) {
      return formatTooltip(params);
    },
  },
  center: ["100%", "50%"],
};

const BasicBarChart = (props) => {
  basicBarTemplate.xAxis.data = [...props.labels];
  basicBarTemplate.series[0].data = [...props.data];
  basicBarTemplate.xAxis.axisLabel.rotate = props.rotateLabels
    ? props.rotateLabels
    : 0;

  return (
    <>
      <ReactECharts option={basicBarTemplate} theme="cool" />
    </>
  );
};

BasicBarChart.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.any,
  rotateLabels: PropTypes.number,
};

export default BasicBarChart;

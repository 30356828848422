import React, {
  forwardRef,
  useState,
  useEffect,
  useImperativeHandle,
} from "react";
import { MDBBtn, MDBIcon } from "mdbreact";
import { tableSort } from "services/MessagingService";
import logger from "services/LoggingService";
import inputData from "./DaaMapGeoData.json";
import { useDispatch } from "react-redux";
import { updateDaaState } from "stateMgmt/daaSlice";

import "./DAAMapPage.scss";

export const getFyIndicators = (year) => {
  return year === "FY2022"
    ? inputData.trackedIndicators[1]
    : inputData.trackedIndicators[0];
};

export const missing = (
  <MDBBtn size="sm" className="mBtn" disabled rounded outline>
    M
  </MDBBtn>
);

export const fine = (
  <MDBBtn size="sm" className="fBtn" disabled rounded outline>
    F
  </MDBBtn>
);

export const coarse = (
  <MDBBtn size="sm" className="cBtn" disabled rounded outline>
    C
  </MDBBtn>
);

export const total = (
  <MDBBtn size="sm" className="tBtn" disabled rounded outline>
    T
  </MDBBtn>
);

export const colData = [
  {
    label: "",
    field: "id",
    sort: "asc",
  },
  {
    label: "TX_CURR",
    field: "col1",
    sort: "asc",
  },
  {
    label: "TX_NEW",
    field: "col2",
    sort: "asc",
  },
  {
    label: "HTS_TST",
    field: "col3",
    sort: "asc",
  },
  {
    label: "PMTCT_STAT",
    field: "col4",
    sort: "asc",
  },
  {
    label: "PMTCT_ART",
    field: "col5",
    sort: "asc",
  },
  {
    label: "TB_PREV",
    field: "col6",
    sort: "asc",
  },
];

const defaultSortIcon = <MDBIcon icon="sort" className="white-text mr-1" />;
const downSortIcon = <MDBIcon icon="sort-down" className="white-text mr-1" />;
const noData = "No Data";
const hiddenDataCountries = new Map([
  ["FY2021", ["South Africa", "Mozambique", "Ukraine"]],
  ["FY2022", ["South Africa", "Mozambique", "Ukraine", "Vietnam"]],
]);

const fetchTableData = (dataIn) => {
  const memData = JSON.parse(sessionStorage.getItem("currentDAATableRowData"));
  let data = [];

  if (memData === null || memData.length === 0) {
    dataIn.forEach((row) => {
      if (row.properties.tracked) {
        data.push(row);
      }
    });
  } else {
    data = [...memData];
  }
  sessionStorage.setItem("currentDAATableRowData", JSON.stringify(data));
  return data;
};

const formatCell = (mapping, value, firstReport) => {
  let color = "table-dark";
  switch (mapping) {
    case "fine":
    case "fine (50+)":
    case "fine (65+)":
      color = "table-success";
      break;
    case "coarse":
      color = "table-warning";
      break;
    case "missing":
      color = "table-danger";
  }

  const dataStyle = firstReport ? "dataCell firstReportCell" : "dataCell";
  const noDataStyle = "iconCell table-danger";
  // {mapping === "missing" ? "Not Provided" : mapping}
  return (
    <div className="iconRow">
      <div className={`iconCell ${color}`}>{mapping}</div>
      <div className={value === noData ? noDataStyle : dataStyle}>
        {value}
        {value === noData ? "" : "%"}
      </div>
    </div>
  );
};

const getAverages = (rowData, cols) => {
  let avg;
  let sum = 0.0;
  let count = 0;
  const tmp = {
    col0: <div className="table-averages">Global Concordance</div>,
  }; // eslint-disable-line
  const averageRow = [];
  cols.forEach((col) => {
    try {
      rowData.forEach((country) => {
        const target = country[col].props.children[1].props.children[0];

        if (target && target !== noData) {
          ++count;
          sum += parseFloat(target.split("%")[0]);
        }
      });
      avg = (sum / count).toFixed(2);
    } catch (e) {
      avg = 0.0;
    }
    tmp[col] = <div className="table-averages">{avg + "%"}</div>; // eslint-disable-line
  });
  averageRow.push(tmp);
  return averageRow;
};
let currentSortButton = 0;

const MapData = forwardRef(function MapData(props, ref) {
  const dispatch = useDispatch();
  const getSortButtons = (fiscalYear, cols) => {
    const averageRow = [];
    const tmp = {};
    const trackedIndicators = getFyIndicators(fiscalYear);

    cols.forEach((col, index) => {
      tmp[col] = (
        <div className="daaSortBtnDiv">
          <MDBBtn
            className="rounded daaSortBtn"
            color="#00467F"
            onClick={() => {
              handleSortEvent(index);
            }}
          >
            {currentSortButton === index ? downSortIcon : defaultSortIcon}
            {index === 0 ? "Country" : trackedIndicators[index - 1]}
          </MDBBtn>
        </div>
      );
    });
    averageRow.push(tmp);
    return averageRow;
  };

  const originalData = fetchTableData(inputData.features);
  const generateRowData = (fiscalYear) => {
    const tmp = [];
    const cols =
      fiscalYear === "FY2021"
        ? ["col0", "col1", "col2", "col3", "col4", "col5", "col6"]
        : [
            "col0",
            "col1",
            "col2",
            "col3",
            "col4",
            "col5",
            "col6",
            "col7",
            "col8",
          ];
    const test = hiddenDataCountries.get(fiscalYear);
    filteredData.forEach((data) => {
      const dat = data.properties.rowData[fiscalYear];
      if (!test.includes(data.properties.name)) {
        const row = {};
        row.col0 = (
          <div className="countryCell">{data.properties.rowData.name}</div>
        );
        dat.forEach((entry, index) => {
          const key = "col" + (index + 1);
          row[key] = formatCell(
            entry.discordance,
            entry.value,
            entry.firstReport
          );
        });
        tmp.push(row);
      }
    });

    const sortButtons = getSortButtons(fiscalYear, cols);
    const averages = getAverages(tmp, cols);
    return sortButtons.concat(averages.concat(tmp));
  };
  const [filteredData, setFilteredData] = useState([...originalData]);
  const [currentRowData, setCurrentRowData] = useState(
    generateRowData("FY2021")
  );

  useImperativeHandle(ref, () => ({
    getSortedRowData(col, year) {
      sortData(col === "" ? 0 : col, year);
      const rowData = generateRowData(year);
      setCurrentRowData([...rowData]);
      return currentRowData;
    },
  }));

  useEffect(() => {
    logger.debug("MapData useEffect called");
    const tmp = fetchTableData(inputData.features);
    const rowData = generateRowData("FY2021");
    setFilteredData([...tmp]);
    setCurrentRowData([...rowData]);
    logger.debug("MapData useEffect complete. RowData: ", rowData);
  }, []);

  function sortData(row, year) {
    try {
      const tmp =
        row === 0
          ? [...originalData]
          : filteredData.sort(
              (a, b) =>
                a.properties.rowData[year][row - 1].sortOrder -
                  b.properties.rowData[year][row - 1].sortOrder ||
                a.properties.rowData.name.localeCompare(
                  b.properties.rowData.name
                )
            );

      setFilteredData(tmp);
    } catch (e) {
      console.error(e);
    }
  }

  function handleSortEvent(btnNum) {
    logger.debug(`Calling table sort. Button: ${btnNum}`);
    currentSortButton = btnNum;
    dispatch(
      updateDaaState({
        sortRow: btnNum,
      })
    );

    tableSort.next(btnNum);
  }

  return <></>;
});

export default MapData;

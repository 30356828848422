import React from "react";
import SecondaryPageContainer from "components/SecondaryPageContainer/SecondaryPageContainer";
import { NavLink } from "react-router-dom";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";

const InfoDisclaimerPage = (props) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/InfoDisclaimerPage/InfoDisclaimerPage.js",
    title: "Info Disclaimer",
  });

  const pageContents = (
    <div>
      <h1 id="InfoDisclaimer_page-top" data-testid="InfoDisclaimer_Page">
        Information Disclaimer
      </h1>
      <p>
        The{" "}
        <a
          href="https://www.state.gov/pepfar/"
          target="_blank"
          rel="noopener noreferrer"
        >
          state.gov/PEPFAR
        </a>{" "}
        website and <NavLink to="/">data.pepfar.gov</NavLink> serve as the State
        Department Office of the U.S. Global AIDS Coordinator&apos;s portal of
        information about the U.S. President&apos;s Emergency Plan for AIDS
        Relief (PEPFAR). They are managed by the Office of the U.S. Global AIDS
        Coordinator and the Office of Electronic Information, Bureau of Public
        Affairs. Email:{" "}
        <a
          href="mailto:CA_SGAC_Public_Affairs@state.gov?subject=Question/Comment%20from%20PEPFAR%20Dashboard"
          className="link"
        >
          CA_SGAC_Public_Affairs@state.gov
        </a>
        . Phone: 202-663-2708.
      </p>
      <p>
        Every effort has been made to provide accurate and complete information.
        However, we cannot guarantee that there will be no errors. Neither the
        U.S. Government nor the State Department assumes any legal liability for
        the accuracy, completeness, or usefulness of any information, product,
        or process disclosed herein, or represents that use of such information,
        product, or process would not infringe on privately owned rights.
      </p>
    </div>
  );

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId="InfoDisclaimer_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

InfoDisclaimerPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default InfoDisclaimerPage;

import React, { Component } from "react";
import MSTRPageContainer from "../MSTRPageContainer/MSTRPageContainer";
import loadingSvgImage from "../../../assets/images/loading.svg";
import PropTypes from "prop-types";
import mstrDashboardsConfig from "assets/configs/mstr-dashboards-config.json";
// import logger from "services/LoggingService";
import ReactGA from "react-ga4";
import * as common from "common";

class LibraryPage extends Component {
  constructor(props) {
    super(props);

    ReactGA.send({
      hitType: "pageview",
      page: "/components/MainContentArea/LibraryPage/LibraryPage.js",
      title: "Additional Data",
    });

    this.libOpen = false;
    this.reload = false;
    this.libraryUrl = null;
    const urlToUse = common.getMicroStrategyUrl();
    this.libraryUrl = mstrDashboardsConfig.mstrDashboardLinks.find(function (
      element
    ) {
      return element.name === "Library";
    })[urlToUse];

    this.state = {
      iFrameLoadedSuccessfully: false,
    };
    this.iFrameLoaderListener = this.iFrameLoaderListener.bind(this);
    this.refreshIFrameContents = this.refreshIFrameContents.bind(this);
    this.timerID = null;
    this.keyID = 1;
  }

  iFrameLoaderListener() {
    this.setState({
      iFrameLoadedSuccessfully: true,
    });
  }

  refreshIFrameContents() {
    /* Note: In order to trigger a re-render of the iFrame using this.forceUpdate(), something within the iFrame needs to change
            In this case, I am forcing an update by changing the key of the iFrame */
    this.keyID = this.keyID + 1;
    this.forceUpdate();
  }

  componentWillUnmount() {
    window.clearInterval(this.timerID);
  }

  componentDidMount() {
    /* Note: Set this refreshTimeInterval to a value < the MSTR 'web user idle session', currently set to 176 minutes */
    const refreshTimeInterval = 9900000; // 2 Hours 45 Minutes
    // let refreshTimeInterval = 240000;   //4 minutes - Can be used for TESTING
    this.timerID = window.setInterval(
      this.refreshIFrameContents,
      refreshTimeInterval
    );
  }

  checkForRefresh() {
    if (!this.libOpen) {
      this.libOpen = true;
    } else if (!this.reload) {
      this.reload = true;
    } else {
      this.keyID = this.keyID + 1;
      this.reload = false;
    }
  }

  render() {
    this.checkForRefresh();

    const loadingSpinnerStyle = {
      textAlign: "center",
    };

    let iFrameNotLoadingInfoMessage = null;
    if (!this.state.iFrameLoadedSuccessfully) {
      iFrameNotLoadingInfoMessage = (
        <div className="iframe-not-loading__div" style={loadingSpinnerStyle}>
          <img src={loadingSvgImage} alt="loading" />
          <p>Loading...</p>
        </div>
      );
    }

    // const dashboardName = this.props.dashboardName

    const iFrameContents = (
      <iframe
        id="dashFrame"
        width="100%"
        frameBorder="0"
        title={"Library MSTR iFrame"}
        key={this.keyID}
        src={this.libraryUrl}
        onLoad={this.iFrameLoaderListener}
      />
    );

    let mstrIFrameDivLeftMargin = null;
    if (this.props.isSidebarCollapsed) {
      mstrIFrameDivLeftMargin = { left: "0px" };
    }

    const pageContents = (
      <div id="mstr-iframe__div" style={mstrIFrameDivLeftMargin}>
        {iFrameNotLoadingInfoMessage}
        {iFrameContents}
      </div>
    );

    return (
      <MSTRPageContainer
        contents={pageContents}
        isSidebarCollapsed={this.props.isSidebarCollapsed}
      />
    );
  }
}

LibraryPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default LibraryPage;

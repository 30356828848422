// import React from "react";
import "./DAAMapPage.scss";

export const getMappingDefinition = (discordance) => {
  let retVal;

  switch (discordance) {
    case "fine (50+)":
      retVal = "Consisting of sex and detailed<br />age bands (50+ and <65)";
      break;
    case "fine (65+)":
      retVal = "Consisting of sex and detailed<br />age bands (65+)";
      break;
    case "fine":
      retVal =
        "Consisting of sex and detailed<br />age bands (five-year increments)";
      break;
    case "coarse":
      retVal = "Consisting of sex and coarse<br />age bands (<15 and 15+)";
      break;
    default:
      retVal = " ";
  }
  return retVal;
};

const indicatorDefinitions = {
  TX_CURR: "People currently receiving<br />antiretroviral therapy",
  TX_NEW: "People newly enrolled on<br />antiretroviral therapy",
  HTS_TST: "Individuals tested for<br />HIV and received<br />their results",
  PMTCT_STAT:
    "Pregnant women attending<br />antenatal care with<br />known HIV status",
  PMTCT_ART: "HIV-positive pregnant<br />women who received ART",
  TB_PREV:
    "ART patients who<br />completed a standard<br />course of TB preventive therapy",
};

export const getDaaToolTip = (params) => {
  let retVal = false;
  switch (params.inputValue) {
    case -9999:
      retVal = composeToolTip(params, -9999, params.inputTotal);
      break;
    case -1:
      retVal = composeToolTip(params, "No Data", params.inputTotal);
      break;

    default:
      retVal = composeToolTip(
        params,
        isNaN(params.inputValue) ? params.inputValue : params.inputValue + "%",
        params.inputTotal
      );
  }
  return retVal;
};

const composeToolTip = (params) => {
  const blank =
    '<tr><td style="height: 0px; padding: 0px; border: 0; vertical-align: top;"></td><td style="height: 0px; padding: 0px; border: 0;"></td></tr>';
  const total =
    params.inputTotal === undefined ? "-----------" : params.inputTotal;
  const discordance = params.currentIndicator.discordance;
  const mappingDefs = getMappingDefinition(discordance); // eslint-disable-line
  const indDefs = indicatorDefinitions[params.currentIndicator.ind];

  const countryRow = `<thead><th colSpan= 2>${params.name}</th></thead>`;
  const indicatorRow = `<tr><td colspan=2>INDICATOR: ${params.currentIndicator.ind}</td></tr>${blank}`;
  const indicatorDefRow = `<tr><td>Indicator Definition:</td><td>${indDefs}</td></tr>${blank}`;
  const disaggRow = `<tr><td>Disaggregation:</td><td>${discordance.toUpperCase()}</td></tr>${blank}`;
  const mappingDefRow = `<tr><td>Disaggregation Definition:</td><td>${mappingDefs}</td></tr>${blank}`;
  const valueRow =
    params.inputValue === -1
      ? "<tr><td>Concordance:</td><td>No Data</td></tr>"
      : `<tr><td>Concordance:</td><td>${params.inputValue}%</td></tr>${blank}`;
  const totalRow = `<tr style="word-wrap: break-word"><td>MoH/PEPFAR Contributions Total:</td><td>${total}</td></tr>${blank}`;

  return (
    "<table id='ttip' style='width:35vw'>" +
    countryRow +
    indicatorRow +
    indicatorDefRow +
    disaggRow +
    mappingDefRow +
    valueRow +
    totalRow +
    "</table>"
  );
};

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import * as common from "common";
import sidebarItems from "assets/data/sidebarItems";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { v4 as uuid } from "uuid";
// import logger from "services/LoggingService";

import "./DropDownNavMenu.scss";

const DropDownNavMenu = (props) => {
  const appContext = useSelector(selectCompliance).context;
  const [isHidden, setIsHidden] = useState(false);
  let urlToUse = null;
  if (common.useMicrostrategyDev()) {
    urlToUse = "externalLinkDevURL";
  } else {
    urlToUse = "externalLinkProdURL";
  }

  let drawerClasses = "drop-down-menu";
  if (props.show) {
    drawerClasses = "drop-down-menu open";
  }

  useEffect(() => {
    const hide = appContext !== "home";
    setIsHidden(hide);
  });

  return (
    <nav className={drawerClasses} aria-hidden={isHidden}>
      {/* START: SideNav */}
      <ul data-testid="drop-nav" className="nav flex-column dropdown-ul">
        {sidebarItems.map((sidebarItem, sidebarItemIndex) =>
          typeof sidebarItem.externalLinkDevURL === "undefined" ? (
            <li
              className="nav-item liStyles nav-line"
              key={uuid()}
              tabIndex={-1}
            >
              <NavLink
                className="text-muted dropdown-nav-link"
                key={uuid()}
                onClick={props.dropDownMenuClickHandler}
                to={sidebarItem.routePath}
                exact
                activeStyle={{}}
                tabIndex={-1}
              >
                {sidebarItem.name}
              </NavLink>
            </li>
          ) : (
            <li
              className="nav-item liStyles nav-line"
              key={uuid()}
              tabIndex={-1}
            >
              <a
                target="_blank"
                rel="noopener noreferrer dropdown-anchor"
                key={uuid()}
                className="text-muted dropdown-nav-link"
                href={sidebarItem[urlToUse]}
                tabIndex={-1}
              >
                {sidebarItem.name}
              </a>
            </li>
          )
        )}
      </ul>
      {/* END: SideNav */}
    </nav>
  );
};

DropDownNavMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  dropDownMenuClickHandler: PropTypes.func.isRequired,
};
export default DropDownNavMenu;

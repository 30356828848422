import React from "react";
import "./GlossaryPage.scss";
import SecondaryPageContainer from "components/SecondaryPageContainer/SecondaryPageContainer";
import glossarySectionsConfig from "assets/configs/glossary-sections-config.json";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";
import { v4 as uuid } from "uuid";
import logger from "services/LoggingService";

const GlossaryPage = (props) => {
  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/GlossaryPage/GlossaryPage.js",
    title: "Glossary",
  });

  const alphabetSectionsMap = new Map();
  const alphabetArray = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const createIndex = () => {
    for (let i = 0; i < alphabetArray.length; i++) {
      alphabetSectionsMap.set(alphabetArray[i], 0);
    }

    for (let i = 0; i < glossarySectionsConfig.sections.length; i++) {
      const currentSectionTitle =
        glossarySectionsConfig.sections[i].sectionTitle;
      const currentFirstCharacter = currentSectionTitle.charAt(0);
      if (alphabetSectionsMap.get(currentFirstCharacter) === 0) {
        glossarySectionsConfig.sections[i].id = currentFirstCharacter;
        const currentCharacterCount = alphabetSectionsMap.get(
          currentFirstCharacter
        );
        alphabetSectionsMap.set(
          currentFirstCharacter,
          currentCharacterCount + 1
        );
      }
    }
    logger.debug(`alphabetSectionsMap:`, alphabetSectionsMap);
  };

  createIndex();

  const glossaryByAlphabetSection = (
    <nav className="table-of-contents__div">
      <h4 className="c-nav__heading">Glossary By Alphabet</h4>
      <div id="skip">
        <a href="#glossary-terms" aria-label="Skip to Main Content">
          Skip to Main Content
        </a>
      </div>
      <ul className="c-nav__list c-nav__list--glossary">
        {alphabetArray.map((character, index) => {
          logger.debug(`Searching for ${character}`);
          logger.debug(`Receiving: ${alphabetSectionsMap.get(character)}`);

          return alphabetSectionsMap.get(character) === 0 ? null : (
            <li key={uuid()} className="c-nav__item">
              <a className="c-nav__link" href={"#" + character}>
                {character}
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );

  const glossaryOfTermsSection = (
    <div>
      {glossarySectionsConfig.sections.map((section, index) =>
        typeof section.id === "undefined" ? (
          <dl key={uuid()}>
            <dt>{section.sectionTitle}</dt>
            <dd>
              {section.paragraphs.map((paragraph, index) => (
                <p key={uuid()}>{paragraph}</p>
              ))}
            </dd>
          </dl>
        ) : (
          <dl id={section.id} key={uuid()}>
            <dt>{section.sectionTitle}</dt>

            <dd>
              {section.paragraphs.map((paragraph, index) => (
                <p key={uuid()}>{paragraph}</p>
              ))}
            </dd>
          </dl>
        )
      )}
    </div>
  );

  const pageContents = (
    <div className="row" id="Glossary_page-top" data-testid="Glossary_Page">
      <div className="col-lg-3">{glossaryByAlphabetSection}</div>
      <div id="glossary-terms" className="col-lg-9 text-left">
        <h1>Glossary of Terms</h1>
        {glossaryOfTermsSection}
      </div>
    </div>
  );

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId="Glossary_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

GlossaryPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default GlossaryPage;

import "babel-polyfill";
import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./registerServiceWorker";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./bootstrap-overrides.scss";
import App from "./App";
import store from "./stateMgmt/store";
import { Provider } from "react-redux";
import logger from "./services/LoggingService";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import "./index.scss";

const container = document.getElementById("root");
const root = createRoot(container);
logger.error("INDEX: ", process.env.REACT_APP_ANA);
ReactGA.initialize(process.env.REACT_APP_ANA, { debug: true });

ReactGA.send({
  hitType: "pageview",
  page: "./index.js",
  title: "Visit Spotlight Site",
});

let fontTheme = createTheme();
fontTheme = responsiveFontSizes(fontTheme);

const tagManagerArgs = {
  gtmId: "GTM-MRHVX6B",
};

TagManager.initialize(tagManagerArgs);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={fontTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>
);

serviceWorker.unregister();

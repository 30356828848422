import { createSlice } from "@reduxjs/toolkit";
import daaData from "components/DAAMapPage/DaaMapGeoData.json";

const initialState = {
  displayedYear: "FY2021",
  fyYearIndex: 0,
  fiscalYears: ["FY2021", "FY2022"],
  currentIndicatorIndex: 0,
  currentIndicator: "TX_CURR",
  currentTableData: [],
  btnSelected: 0,
  buttonBar: [],
  sortRow: 0,
  showLabels: false,
  trackedIndicators: daaData.trackedIndicators[0],
  countries: [],
  labels: [],
};

const getCountries = () => {
  const tmpCountries = [];
  const tmpLabels = [];

  daaData.features.forEach((country) => {
    if (country.properties.tracked) {
      const value = country.properties.name;
      const text = country.properties.name;
      tmpCountries.push({ text, value });
      tmpLabels.push(country.properties.name);
    }
  });

  return { countries: tmpCountries, labels: tmpLabels };
};

export const daaSlice = createSlice({
  name: "daaState",
  initialState,
  reducers: {
    initDaa: (state, action) => {
      const tmp = getCountries();

      const newState = {
        ...state,
        countries: [...tmp.countries],
        labels: [...tmp.labels],
      };
      return newState;
    },
    updateDaaState: (state, action) => {
      const newState = {
        ...state,
        ...action.payload,
      };
      return newState;
    },
  },
});

export const selectDaa = (state) => state.daaState;
export const { initDaa, updateDaaState } = daaSlice.actions;
export default daaSlice.reducer;

import React, { useEffect, useState } from "react";
import { Collapse } from "mdbreact";
import ReactHtmlParser from "react-html-parser";
import "./CollapsibleSection.scss";
import { NavLink } from "react-router-dom";
import refSheet2017 from "assets/reference/MER_2017_Indicator_Reference_Sheets.zip";
import refSheet2016 from "assets/reference/MER_2016_Indicator_Reference_Sheets.zip";
import refSheet2015 from "assets/reference/MER_2015_Indicator_Reference_Sheets.zip";
import refSheet2013 from "assets/reference/2013-2014_Indicator_Reference_Sheets.zip";
import refSheet2009 from "assets/reference/2009-2012_Indicator_Reference_Sheets.zip";
import refSheet2004 from "assets/reference/2004-2008_Indicator_Reference_Sheets.zip";
import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";

const CollapsibleSection = (props) => {
  const [collapsed, setCollapse] = useState(false);
  let currentCollapsibleIcon = (
    <i className="far fa-plus-square collapsible-icon"></i>
  );

  useEffect(() => {
    if (!collapsed) {
      currentCollapsibleIcon = (
        <i className="far fa-plus-square collapsible-icon"></i>
      );
    } else {
      currentCollapsibleIcon = (
        <i className="far fa-minus-square collapsible-icon"></i>
      );
    }
  });

  const toggle = () => {
    setCollapse(!collapsed);
  };

  return (
    <div>
      <div className="collapsible-outer__div" onClick={toggle}>
        {currentCollapsibleIcon}
        <div className="collapsible-title-text">
          {props.collapsibleSectionHeader}
        </div>
      </div>
      <Collapse isOpen={collapsed}>
        {/* The following code iterates through the collapsibleSectionParagraphs and takes them from the JSON file into HTML <p> paragraphs.
              There is a special case where some internal links are referenced, which had to be done here and not in JSON file. */}
        {props.collapsibleSectionParagraphs.map(
          (sectionParagraph, sectionParagraphIndex) =>
            props.collapsibleSectionHeader ===
            "Where can I find more information on how these indicators are defined?" ? (
              <div className="collapse show" key={uuid()}>
                <p className="collapsible-paragraph">
                  The indicator reference sheets can be downloaded from
                  <ul>
                    <li>
                      <a
                        href=" https://datim.zendesk.com/hc/en-us/articles/360000084446-MER-Indicator-Reference-Guides"
                        target="_blank"
                      >
                        here
                      </a>{" "}
                      for 2018+ results
                    </li>
                    <li>
                      <a href={refSheet2017}>here</a> for 2017 results
                    </li>
                    <li>
                      <a href={refSheet2016}>here</a> for 2016 results
                    </li>
                    <li>
                      <a href={refSheet2015}>here</a> for 2015 results
                    </li>
                    <li>
                      <a href={refSheet2013}>here</a> for 2013-2014 results
                    </li>
                    <li>
                      <a href={refSheet2009}>here</a> for 2009-2012 results
                    </li>
                    <li>
                      <a href={refSheet2004}>here</a> for 2004-2008 results
                    </li>
                  </ul>
                </p>
                <p className="collapsible-paragraph">
                  In the indicator reference sheets, each indicator is described
                  in detail, including information regarding definitions,
                  methods of measurement, and precautions for interpretation.
                  Please note that for most indicators, there are multiple
                  reference sheets depending on the time period in question.
                  Please see the{" "}
                  <NavLink to="/dataSources">Data Sources</NavLink> page for an
                  explanation of PEPFAR’s reporting phases.
                </p>
              </div>
            ) : (
              <p className="collapsible-paragraph" key={uuid()}>
                {ReactHtmlParser(sectionParagraph)}
              </p>
            )
        )}
      </Collapse>
    </div>
  );
};

CollapsibleSection.propTypes = {
  collapsibleSectionParagraphs: PropTypes.any,
  collapsibleSectionHeader: PropTypes.string,
};

export default CollapsibleSection;

import React from "react";
import CountryStoryMain from "components/CountryStories/CountryStoryMain";
// import { useSelector } from "react-redux";
// import { selectCompliance } from "stateMgmt/complianceSlice";
import InTheSpotlight from "./InTheSpotlight/InTheSpotlight";
import { Grid, Box } from "@mui/material";
import "components/CountryStories/CountryStoryMain.scss";

const carouselRow = () => {
  // const theme = useSelector(selectCompliance).theme;

  return (
    <>
      <div className="carousel-div">
        <Grid
          container
          spacing={2}
          alignItems="start"
          justifyContent="space-between"
        >
          <Grid item xs={9}>
            <Box>
              <CountryStoryMain />
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box>
              <InTheSpotlight />
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default carouselRow;

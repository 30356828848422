import React from "react";
import SecondaryPageContainer from "components/SecondaryPageContainer/SecondaryPageContainer";
import "./CalendarPage.scss";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import PropTypes from "prop-types";

const CalendarPage = (props) => {
  const theme = useSelector(selectCompliance).theme;

  ReactGA.send({
    hitType: "pageview",
    page: "/components/MainContentArea/CalendarPage/CalendarPage.js",
    title: "Calendar",
  });

  const pageContents = (
    // DMA-316 Update Q2 2020 release date.
    <div className="row" id="Calendar_page-top" data-testid="Calendar_Page">
      <div className="col-lg-12 Calendar_page">
        <h1>Data Publish Calendar</h1>
        <table>
          <thead>
            <tr>
              <th>Release Name</th>
              <th>Release Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  color: theme.color,
                }}
              >
                2024 Q1 Spotlight Data Publish
              </td>
              <td
                style={{
                  color: theme.color,
                }}
              >
                Friday, March 22, 2024
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: theme.color,
                }}
              >
                2024 Q2 Spotlight Data Publish
              </td>
              <td
                style={{
                  color: theme.color,
                }}
              >
                Friday, June 25, 2024
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: theme.color,
                }}
              >
                2024 Q3 Spotlight Data Publish
              </td>
              <td
                style={{
                  color: theme.color,
                }}
              >
                Friday, September 20, 2024
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: theme.color,
                }}
              >
                2024 World AIDS Day
              </td>
              <td
                style={{
                  color: theme.color,
                }}
              >
                Sunday, December 1, 2024
              </td>
            </tr>
            <tr>
              <td
                style={{
                  color: theme.color,
                }}
              >
                2024 Q4 Spotlight Data Publish
              </td>
              <td
                style={{
                  color: theme.color,
                }}
              >
                Friday, December 20, 2024
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <SecondaryPageContainer
      contents={pageContents}
      showToc={false}
      pageHeaderId="Calendar_page-top"
      isSidebarCollapsed={props.isSidebarCollapsed}
    />
  );
};

CalendarPage.propTypes = {
  isSidebarCollapsed: PropTypes.bool.isRequired,
};

export default CalendarPage;

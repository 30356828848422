import React, { useState, useEffect } from "react";
import "./LandingPage.scss";
import CarouselRow from "./CarouselRow/CarouselRow";
import ProgramResultsFiguresRow from "./ProgramResultsFiguresRow/ProgramResultsFiguresRow";
import Footer from "components/Footer/Footer";
import jsonFooterConfig from "assets/configs/footer-config.json";
import { useSelector } from "react-redux";
import { selectCompliance } from "stateMgmt/complianceSlice";
import { Box } from "@mui/material";
// import Typography from "@mui/material/Typography";

// import logger from "services/LoggingService";

const mainContent = (props) => {
  const theme = useSelector(selectCompliance).theme;
  const appContext = useSelector(selectCompliance).context;
  const [isHidden, setIsHidden] = useState(false);

  let mainContentCssClasses =
    "landing-page__main landing-page__main__left-margin left-margin-wide-monitors";
  if (props.isSidebarCollapsed) {
    mainContentCssClasses = mainContentCssClasses.replace(
      "landing-page__main__left-margin",
      "landing-page__main__closed-margin"
    );
    mainContentCssClasses = mainContentCssClasses.replace(
      "left-margin-wide-monitors",
      ""
    );
  }

  useEffect(() => {
    const hide = appContext !== "home";
    setIsHidden(hide);
  });

  // FORMAT: landing-page__container (HTML)
  return (
    <div
      id="mainContent"
      style={{ backgroundColor: theme.backgroundColor, color: theme.color }}
      className={mainContentCssClasses}
      role="main"
      tabIndex={-1}
    >
      <div className="container-fluid landing-page__container">
        <ProgramResultsFiguresRow />
        <CarouselRow />
        <div className="row" id="footer_row_iata">
          <div
            className="col-12"
            style={{
              backgroundColor: theme.backgroundColor,
              color: theme.color,
              fontSize: "1.3vh",
            }}
          >
            <Box sx={{ fontSize: "0.6rem" }}>
              Unless otherwise noted, the content, data, documentation, code,
              and related materials on data.pepfar.gov is public domain and made
              available with a{" "}
              <a
                href="http://creativecommons.org/publicdomain/zero/1.0/legalcode"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.linkColor }}
                aria-hidden={isHidden}
                tabIndex={isHidden ? "-1" : 0}
              >
                Creative Commons CC0 1.0 Universal
              </a>{" "}
              dedication and license-free (per US Code{" "}
              <a
                href="http://www.gpo.gov/fdsys/pkg/USCODE-2010-title17/html/USCODE-2010-title17-chap1-sec105.htm"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: theme.linkColor }}
                aria-label="Title 17 section 105 subject matter of copyright"
                aria-hidden={isHidden}
                tabIndex={isHidden ? "-1" : 0}
              >
                17 USC § 105
              </a>
              ). Data.pepfar.gov waive all rights to the work worldwide under
              copyright law, including all related and neighboring rights, to
              the extent allowed by law. You can copy, modify, distribute, and
              perform the work, even for commercial purposes, all without asking
              permission. Citation of data.pepfar.gov as a source of the data is
              appreciated.
            </Box>
          </div>
        </div>
        <Footer footerConfig={jsonFooterConfig} />
      </div>
    </div>
  );
};

export default mainContent;
